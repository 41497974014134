import moment from "moment";
export default {
  name: "GenerateEstimateCollectability",
  data() {
    return {
      dataForm: {
        officeIds: [],
        dateFrom: "",
        dateTo: "",
        profileSurenameCollection: "",
        profileSurenameHandling: "",
      },
      title_app: "",
      property: {
        animation: {
          submitLoading: false,
        },
        listElement: {
          estimasiKolektibilitas: {
            rows: 0,
            perPage: 10,
            currentPage: 1,
            message: "",
            downloading: false,
          },
        },
      },
      options: {
        officeIds: [],
        officeIdsDataSoure: [],
      },
      table: {
        data: {
          estimasiKolektibilitas: [],
        },
      },
    };
  },
  computed: {
    readPeriode() {
      return `${
        this.dataForm.dateFrom
          ? this.dataForm.dateFrom
          : moment(new Date()).format("YYYY-MM-DD")
      } - ${
        this.dataForm.dateTo
          ? this.dataForm.dateTo
          : moment(new Date()).format("YYYY-MM-DD")
      }`;
    },
  },
  methods: {
    async getAsset() {
      const session = sessionStorage.getItem("@vue-session/asset-bundle");
      this.title_app = JSON.parse(window.atob(session)).companyName;
    },
    async getEstimateCollectabilityMixReality() {
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        return;
      }
      const confirmation = await this.simpleConfirmation(
        "Tambah Data Ini ?",
        "is-success"
      );
      if (!confirmation) {
        return;
      }
      this.table.data.estimasiKolektibilitas = [];
      this.property.animation.submitLoading = true;
      this.property.listElement.estimasiKolektibilitas.downloading = true;
      this.property.listElement.estimasiKolektibilitas.currentPage = 1;
      const finalOfficeIdsData = [];
      this.dataForm.officeIds.map((index) => {
        if (index.value) {
          finalOfficeIdsData.push(index.value);
        }
      });
      try {
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          endPoint: "report",
          reqUrl: "report/estimate-collectability-mix-realty",
          payload: {
            officeIds: finalOfficeIdsData,
            dateTo: this.dataForm.dateTo,
            profileSurenameCollection: this.dataForm.profileSurenameCollection,
            profileSurenameHandling: this.dataForm.profileSurenameHandling,
            kodeProduk: this.dataForm.kodeProduk,
            page: 0,
            size: 10,
          },
        });
        if (resp.data.code === "SUCCESS") {
          setTimeout(() => {
            this.table.data.estimasiKolektibilitas = resp.data.data.content;
            this.property.listElement.estimasiKolektibilitas.rows =
              resp.data.data.totalElements;
          }, 1000);
        } else {
          this.property.listElement.estimasiKolektibilitas.message =
            resp.data.message;
        }
      } catch (error) {
        this.table.data.estimasiKolektibilitas = [];
        this.property.listElement.estimasiKolektibilitas.message = error.response
          ? `Gagal Mengunduh Data : ${error.response.statusText}`
          : "Terjadi Kesalahan";
      } finally {
        setTimeout(() => {
          this.property.animation.submitLoading = false;
          this.property.listElement.estimasiKolektibilitas.downloading = false;
        }, 1000);
      }
    },

    async changePaginationEstimateCollectabilityMixReality(event) {
      this.table.data.estimasiKolektibilitas = [];
      this.property.animation.submitLoading = true;
      this.property.listElement.estimasiKolektibilitas.downloading = true;
      const timeout = 500;
      const finalOfficeIdsData = [];
      this.dataForm.officeIds.map((index) => {
        if (index.value) {
          finalOfficeIdsData.push(index.value);
        }
      });
      try {
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          endPoint: "report",
          reqUrl: "report/estimate-collectability-mix-realty",
          payload: {
            officeIds: finalOfficeIdsData,
            dateTo: this.dataForm.dateTo,
            kodeProduk: this.dataForm.kodeProduk,
            profileSurenameCollection: this.dataForm.profileSurenameCollection,
            profileSurenameHandling: this.dataForm.profileSurenameHandling,
            page: event - 1,
            size: this.property.listElement.estimasiKolektibilitas.perPage,
          },
        });
        setTimeout(() => {
          if (resp.data.code === "SUCCESS") {
          }
        }, timeout);
      } catch (error) {
        setTimeout(() => {}, timeout);
      } finally {
        setTimeout(() => {
          this.property.listElement.estimasiKolektibilitas.downloading = false;
        }, timeout);
      }
    },
    selectAllOfficeIds() {
      this.dataForm.officeIds = this.options.officeIds;
      this.options.officeIds = [];
    },
    addOfficeIds(event) {
      var unselectedOffice = this.options.officeIds.filter(
        (index) => index.value !== event.value
      );
      this.options.officeIds = unselectedOffice;
    },
    deleteOfficeIds(event) {
      this.options.officeIds.push(event);
    },
    async helperGetOfficeDataForReference() {
      this.$store
        .dispatch("GET_REFERENCE_FROM_MASTER_PARAMETER", {
          params: {
            officeName: "",
            page: 0,
          },
          url: "office",
        })
        .then((response) => {
          this.options.officeIds = [{ text: "-- Semua Cabang --", value: "" }];
          this.options.officeIdsDataSoure = [
            { text: "-- Semua Cabang --", value: "" },
          ];
          response.data.data.content.map((index) => {
            const value = index.officeId;
            const text = index.officeName;
            this.options.officeIds.push({
              value,
              text,
            });
            this.options.officeIdsDataSoure.push({
              value,
              text,
            });
          });
        });
    },

    filterOfficeIds(text) {
      this.options.officeIds = this.options.officeIdsDataSoure.filter(
        (index) => {
          return (
            index.text
              .toString()
              .toLowerCase()
              .indexOf(text.toLowerCase()) >= 0
          );
        }
      );
    },
    fillDefaultPeriode() {
      moment.locale("ID");
      var dateNow = new Date();
      this.dataForm.dateFrom = moment(dateNow).format("YYYY-MM-DD");
      this.dataForm.dateTo = moment(dateNow).format("YYYY-MM-DD");
    },
  },
  mounted() {
    this.getAsset();
    this.fillDefaultPeriode();
    this.helperGetOfficeDataForReference();
  },
};
